<template>
  <footer>
    <span>Copyright © Antoine Bouquet {{ year }}</span>
    <span class="info">{{ $t('footer.siret_number') + "909 203 317 00021"}}</span>

    <div class="bandeau">
      <div class="left"></div>
      <div class="right"></div>
    </div>

    <span class="info">{{ $t('footer.antoinebouquet2') }}
      <a href="https://antoinebouquet.dev" rel="nofollow" target="_blank">antoinebouquet.dev</a>
    </span>

    <div class="translate">
      <translate></translate>
    </div>

  </footer>
</template>

<script>
import translate from "@/components/translate";

export default {
  name: 'Footer',
  components: {translate},
  data() {
    return {
      year: (new Date()).getFullYear()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import 'src/scss/_variables.scss';

footer {
  padding: 20px 0;
  position: relative;

  span {
    font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  span.info {
    font-size: 11px;
    display: block;
  }

  .bandeau {
    display: flex;
    flex-direction: row;
    padding: 0 15px;
    margin: 10px 0;

    .left, .right {
      flex: 1;
      height: 5px;
    }

    .left {
      background-color: #D90032;
    }

    .right {
      background-color: #274288;
    }
  }

  .translate {
    position: absolute;
    top: 15px;
    right: 15px;
  }
}
</style>
