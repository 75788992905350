<template>
  <header :style="{ 'backgroundImage': 'url(\'' + image + '\')' }">
    <h1>{{ $t("header.hello") }}</h1>
    <h2><span class="highlight">{{ $t("header.position") }}</span><br/>
      <span class="highlight">{{ $t("header.based_in") }} <span class="primary">{{ $t("header.lyon") }}📍</span></span></h2>
    <a href="#about"><font-awesome-icon icon="chevron-circle-down" /></a>
  </header>
</template>

<script>
export default {
  name: 'Header',
  data() {
    const currentHour = (new Date()).getHours()
    return {
      image: currentHour < 19 && currentHour > 8 ? require('@/assets/jour.jpg') : require('@/assets/nuit.jpg')
    };
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import 'src/scss/_variables.scss';

header {
  margin: 0;
  background-color: $default;
  height: 100vh;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;

  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 50px;
  @media screen and (max-width: $small-page-breakpoint) {
    padding: 10px;
  }
  @media screen and (max-width: $medium-page-breakpoint) {
    padding: 25px;
  }

  > * {
    text-align: center;
  }

  h1, h2, h3 {
    color: white;
    margin: 20px 0 10px 0;
  }

  h1 {
    font-size: 60px;
    font-weight: 700;
    font-family: "Caveat Brush", "Helvetica Neue", Helvetica, Arial, sans-serif;


    @media screen and (max-width: $small-page-breakpoint) {
      font-size: 42px;
    }
  }

  h2 {
    font-size: 30px;
    font-weight: 500;
    font-style: italic;
    line-height: 1.7em;

    @media screen and (max-width: $small-page-breakpoint) {
      font-size: 27px;
    }

    .highlight {
      background-size: 100% 85% !important;
      background: black no-repeat 0 40%;
    }

    .primary {
      color: $primary;
    }
  }

  svg {
    color: $primary;
    font-size: 70px;
    cursor: pointer;
    padding: 40px 0;

    &:hover {
      color: $primary-hover;
    }
  }
}
</style>
