<template>
  <nav :class="scrollY < 100 && !menuMobileOpen ? 'transparent' : 'solid'">
    <div class="container">
      <a href="#" class="title">Antoine Bouquet</a>
      <ul class="menu">
        <li v-for="item in items" v-bind:key="item">
          <a :href="item.link" :class="{ active: item.id === currentSectionId }">{{ $t(item.text).toUpperCase() }}</a>
        </li>
      </ul>

      <div class="button-menu-mobile" v-on:click="menuMobileOpen = !menuMobileOpen">
        <span>{{ $t('navigation.menu').toUpperCase() }}</span>
        <font-awesome-icon icon="bars" />
      </div>
    </div>

    <ul class="menu-mobile" v-if="menuMobileOpen">
      <li v-for="item in items" v-bind:key="item">
        <a :href="item.link" :class="{ active: item.id === currentSectionId }">{{ $t(item.text).toUpperCase() }}</a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'Navigation',
  data() {
    return {
      items: [
        {id: 'about', link: '#about', text: 'navigation.about', top: null},
        {id: 'projects', link: '#projects', text: 'navigation.projects', top: null},
        {id: 'contact', link: '#contact', text: 'navigation.contact', top: null},
      ],
      scrollY: null,
      currentSectionId: null,
      menuMobileOpen: false
    };
  },
  methods: {
    updateScroll() {
      this.scrollY = window.scrollY;

      let itemsAbove = this.items.filter((item) => item.top != null && item.top <= this.scrollY);
      if(itemsAbove != null && itemsAbove.length > 0) {
        this.currentSectionId = itemsAbove[itemsAbove.length - 1].id;
      } else {
        this.currentSectionId = null;
      }
    },
    reloadSectionTopPosition() {
      let topSectionInterval = setInterval(() => {
        let result = true;
        this.items.forEach((item) => {
          if (document.getElementById(item.id) != null && document.getElementById(item.id).getBoundingClientRect() != null) {
            item.top = document.getElementById(item.id).getBoundingClientRect().top + window.scrollY;
          } else {
            result = false;
          }
        });

        if(result) {
          this.items = this.items.sort((itemA, itemB) => itemA.top - itemB.top);
          clearInterval(topSectionInterval);
        }
      }, 1000);
    }
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll);

    this.reloadSectionTopPosition();
    window.addEventListener('resize', this.reloadSectionTopPosition);
  },
  destroy() {
    window.removeEventListener('scroll', this.updateScroll);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import 'src/scss/_variables.scss';

nav {
  position: fixed;
  top: 0;
  left: 0;
  background-color: $default;
  width: 100%;
  z-index: 1000000;

  transition: all 0.3s ease;

  .container {
    display: flex;
    padding: 10px 100px;

    @media screen and (max-width: $medium-page-breakpoint) {
      padding-left: 70px;
      padding-right: 70px;
    }

    @media screen and (max-width: $small-page-breakpoint) {
      padding-left: 50px;
      padding-right: 50px;
    }

    @media screen and (max-width: $very-small-page-breakpoint) {
      padding-left: 20px;
      padding-right: 20px;
    }

    justify-content: space-between;
    align-items: center;

    .title {
      color: $primary;
      font-family: "Lobster", "Helvetica Neue", Helvetica, Arial, cursive;
      font-size: 28px;
      text-decoration: none;

      @media screen and (max-width: $very-small-page-breakpoint) {
        font-size: 20px;
      }
    }

    .menu {
      @media screen and (max-width: $small-page-breakpoint) {
        display:none;
      }
    }

    .button-menu-mobile {
      display: none;
      background-color: $primary;
      border-radius: 10px;
      padding: 10px 8px;
      font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 12px;
      font-weight: 600;
      cursor: pointer;

      &:hover {
        background-color: $primary-hover;
      }

      span {
        margin-right: 5px;
      }

      svg {
        margin-top: -1px;
      }

      @media screen and (max-width: $small-page-breakpoint) {
        display:flex;
        justify-content: center;
        align-items: center;
      }
    }
  }



  .menu, .menu-mobile {
    list-style: none outside none;
    font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;

    li {
      display: inline-block;
      zoom: 1;
      *display: inline;

      a {
        padding: 15px;
        color: white;
        text-decoration: none;
        font-size: 14px;
        border-radius: 3px;
      }

      a:hover {
        color: $primary;
      }

      a.active {
        background-color: $primary;
        color: $default;
      }
    }
  }

  .menu-mobile {
    display:flex;
    flex-direction: column;
    padding-inline-start: 0;
    margin: 5px 0 0 0;
    border-top: 1px $default-hover solid;

    li {
      width: 100%;
      display: flex;
      flex: 1;

      a {
        border-radius: 0;
        flex: 1;
        padding: 10px 15px;
        width: 100%;
      }
    }


    @media screen and (min-width: $small-page-breakpoint) {
      display: none;
    }
  }
}

nav.transparent {
  background-color: transparent;

  @media screen and (min-width: $small-page-breakpoint) {
    transform: scale(1.05);
  }
}

</style>
