<template>
  <Navigation></Navigation>
  <Header></Header>
  <About></About>
  <Projects></Projects>
  <Contact></Contact>
  <Footer></Footer>
</template>

<script>
import Navigation from './views/Navigation.vue'
import Header from "@/views/Header";
import About from "@/views/sections/about/About";
import Projects from "@/views/sections/projects/Projects";
import Contact from "@/views/sections/contact/Contact";
import Footer from "@/views/Footer";

export default {
  name: 'App',
  components: {
    Footer,
    Projects,
    About,
    Contact,
    Navigation,
    Header
  }
}
</script>

<style lang="scss">
@import 'src/scss/_variables.scss';

html {
  scroll-behavior: smooth !important;
}

body {
  margin: 0;
}

#app {
  font-family: "Droid Serif", Helvetica, Arial, sans-serif;
  color: $text-color;

  -webkit-tap-highlight-color: transparent;

  ::selection {
    text-shadow: none;
    background: $primary;
  }
}

section {
  min-height: 100vh;
  padding: 50px 100px;

  @media screen and (max-width: $small-page-breakpoint) {
    padding: 50px 38px;
  }

  @media screen and (max-width: $medium-page-breakpoint) {
    padding: 50px 60px;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  h1, h2 {
    font-family: "Caveat Brush", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 700;
  }

  h1 {
    font-size: 50px;
    margin: 10px 0;
  }

  h2 {
    font-size: 30px;
  }

  h3 {
    font-size: 16px;
    font-weight: 200;
    color: #777;
  }
}
</style>
