<template>
  <section id="contact" class="container">
    <h1>{{ $t('contact.title') }}</h1>

    <h3>{{ $t('contact.subtitle') }}</h3>

    <form @submit="submit">
      <input v-model="name" type="text" name="name" :placeholder="$t('contact.name')">
      <span class="error" v-if="errorName">{{ $t('contact.name_error') }}</span>

      <input v-model="email" type="email" name="email" :placeholder="$t('contact.email')">
      <span class="error" v-if="errorEmail">{{ $t('contact.email_error') }}</span>

      <textarea v-model="message" name="message" placeholder='<script type="text/javascript">

  // a complex script. tell me if you solved it.

  let i = 0;
  while(i < 1) {
    console.log("im gonna become the master of the universe!")
  }

  console.log("its done, yeaha!");

</script>'/>
      <span class="error" v-if="errorMessage">{{ $t('contact.message_error') }}</span>

      <div class="recaptcha">
        <vue-recaptcha class="g-recaptcha" sitekey="6LcHnyQTAAAAAHdKaPuliT6_5acEZ_qyV7sEJ4Ue"
                       size="normal"
                       theme="dark"
                       @verify="recaptchaVerified"
                       ref="vueRecaptcha" hl="fr">
        </vue-recaptcha>
        <span class="error" v-if="errorRecaptcha">{{ $t('contact.captcha_error') }}</span>
      </div>

      <button type="submit">
        <span v-if="!loading">{{ $t('contact.ping') }}</span>
        <loader v-if="loading"></loader>
      </button>
    </form>

  </section>
</template>

<script>
import loader from "@/components/loader";
import axios from "axios";
import {createToast} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
import vueRecaptcha from 'vue3-recaptcha2';

export default {
  name: 'Contact',
  components: {loader, vueRecaptcha},
  data() {
    return {
      name: null,
      email: null,
      message: null,
      captcha: null,
      errorName: false,
      errorEmail: false,
      errorMessage: false,
      errorRecaptcha: false,
      loading: false
    };
  },
  methods: {
    submit: function (e) {
      if (!this.loading && this.checkForm()) {
        this.loading = true;
        axios.post('https://portfolio.antoinebouquet.fr/api/contact',
            {
              name: this.name,
              email: this.email,
              message: this.message,
              recaptcha: this.captcha
            })
            .then(() => {
              createToast(this.$i18n.t('contact.success_message', this.$i18n.locale), {position: 'bottom-right', type: 'success'});
              this.loading = false;
              this.message = null;
              this.name = null;
              this.email = null;
              this.captcha = null;
            })
            .catch(error => {
              console.error(error);
              let message = this.$i18n.t('contact.error_message', this.$i18n.locale);

              if (error.response?.status === 400) {
                message = error.response.data;
              }

              createToast(message, {position: 'bottom-right', type: 'danger'});
              this.loading = false;
            })
      }

      e.preventDefault();
    },
    checkForm: function () {
      this.errorName = false;
      this.errorEmail = false;
      this.errorMessage = false;
      this.errorRecaptcha = false;

      if (this.name && this.email && this.message && this.captcha) {
        return true;
      }

      if (!this.name) {
        this.errorName = true;
      }
      if (!this.email) {
        this.errorEmail = true;
      }
      if (!this.message) {
        this.errorMessage = true;
      }
      if (!this.captcha) {
        this.errorRecaptcha = true;
      }

      return false;
    },
    recaptchaVerified: function (value) {
      this.captcha = value;
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/variables';

.container {
  background-color: #282828;

  h1 {
    color: white
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;

    @media screen and (max-width: $very-small-page-breakpoint) {
      width: 282px; // same width recaptcha
    }

    input, textarea {
      background-color: black;
      width: 100%;
      color: white;
      font-size: 14px;
      margin: 5px 0;
      padding: 15px 10px;
      font-family: "Droid Serif", Helvetica, Arial, sans-serif;
      border-radius: 3px;
      border: 1px grey dashed;

      &::placeholder {
        color: #999999;
      }

      &:focus-visible, &:focus {
        border: 1px solid $primary;
        outline: none;
        box-shadow: none;
      }
    }

    textarea {
      height: 200px;
      resize: none;

      &::placeholder {
        font-family: "Courier New", sans-serif;
        font-size: 13px;
      }
    }

    .recaptcha {
      margin: 20px 0;

      .g-recaptcha {
        margin: 5px 0;
      }

      span {
        align-self: center;
      }
    }

    button {
      background-color: $primary;
      cursor: pointer;
      width: 100px;
      height: 50px;
      border-radius: 3px;
      border: none;

      span {
        font-size: 16px;
        font-family: "Droid Serif", Helvetica, Arial, sans-serif;
      }

      &:hover {
        background-color: $primary-hover;
      }

      .loader {
        left: 7px;
      }
    }

    span.error {
      color: indianred;
      font-size: 13px;
      align-self: flex-start;
    }

  }
}

</style>
