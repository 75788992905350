<template>
  <section id="projects" class="container">
    <h1>{{ $t('projects.title') }}</h1>

    <h3>{{ $t('projects.subtitle') }}</h3>

    <div class="projects">
      <ProjectItem v-for="project of projects" v-bind:key="project" :project="project"/>
    </div>

  </section>
</template>

<script>
import ProjectItem from './ProjectItem.vue';

export default {
  name: 'Projects',
  components: {
    ProjectItem
  },
  data() {
    return {
      projects: [{
        image: require('@/assets/portfolio/ravup.png'),
        title: 'Ravup',
        description: 'projects.ravup',
        tags: ['flutter', 'nodejs', '2021'],
        links: [{name: 'projects.android', url: 'https://play.google.com/store/apps/details?id=com.ravup.app'},
          {name: 'projects.ios', url: 'https://apps.apple.com/fr/app/ravup/id1571571014'},
          {name: 'projects.website', url: 'https://ravup.app'},]
      }, {
        image: require('@/assets/portfolio/ballers.png'),
        title: 'Ballers app',
        description: 'projects.ballers',
        tags: ['flutter', 'nodejs', 'angular', '2020'],
        links: [{name: 'projects.library', url: 'https://www.npmjs.com/package/stats-fr-emarque-basketball-extractor'}]
      }, {
        image: require('@/assets/portfolio/jessicacharvier.png'),
        title: 'Jessica Charvier',
        description: 'projects.jessicacharvier',
        tags: ['angular', '2020'],
        links: [{name: 'projects.website', url: 'http://jessica.antoinebouquet.fr'}]
      }, {
        image: require('@/assets/portfolio/citieslocator.png'),
        title: 'Cities Locator',
        description: 'projects.citieslocator',
        tags: ['ionic', 'nodejs', '2017'],
        links: []
      }, {
        image: require('@/assets/portfolio/sudokuonline.png'),
        title: 'Panda Sudoku',
        description: 'projects.sudoku',
        tags: ['android native', '2016'],
        links: [{name: 'projects.store', url: 'https://play.google.com/store/apps/details?id=com.antoine.sudokuonline&hl=fr&gl=US'}]
      }, {
        image: require('@/assets/portfolio/constructionsnuguet.png'),
        title: 'Constructions Nuguet',
        description: 'projects.constructionsnuguet',
        tags: ['bootstrap', '2015'],
        links: [{name: 'projects.website', url: 'http://tp.antoinebouquet.fr/ConstructionsNuguet'}]
      }, {
        image: require('@/assets/portfolio/mgcuisine.png'),
        title: 'MG Cuisine',
        description: 'projects.mgcuisine',
        tags: ['bootstrap', '2018'],
        links: [{name: 'projects.website', url: 'http://tp.antoinebouquet.fr/mgcuisine/'}]
      }]
    };
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import 'src/scss/variables';

.container {
  background-color: #e5e5e5;

  .projects {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    padding: 30px 100px;

    @media screen and (max-width: $large-page-breakpoint) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

</style>
