<template>
  <section id="about" class="container">
    <img src="@/assets/about/me.jpg" :alt="$t('about.itsme')">

    <div class="social-buttons">
      <a class="social-button" v-for="social in socials" v-bind:key="social" :href="social.link" rel="nofollow"
         target="_blank">
        <font-awesome-icon v-if="! social.custom" :icon="['fab', social.icon]"/>
        <img v-if="social.custom" :src="social.icon" :alt="social.name"/>
      </a>
    </div>

    <h1>{{ $t('about.whoami') }}</h1>

    <p>
      {{ $t('about.1stSentence1stPart') }}
      <span class="info" v-tooltip="$t('about.expCalculation')">
        {{ $t('about.expTime', {nbYearsExp: nbYearsExp, nbDaysExp: nbDaysExp}) }}
      </span>
      {{ $t('about.1stSentence2ndPart') }}
    </p>

    <p>
      {{ $t('about.2ndSentence1stPart') }}
      <a href="https://www.cpe.fr" rel="nofollow" target="_blank" class="link-school">CPE Lyon</a>
      {{ $t('about.2ndSentence2ndPart') }}
    </p>

    <p>{{ $t('about.looking_for_position') }} - <i>{{ $t('about.looking_for_place') }} </i></p>

    <h2>Technologies I <span class="heart">💙</span> to work with</h2>

    <div class="technologies">
      <div class="technology" v-for="technology in technologies" v-bind:key="technology"
           v-tooltip.bottom="technology.name">
        <font-awesome-icon v-if="! technology.custom" :icon="['fab', technology.icon]"/>
        <img v-if="technology.custom" :src="technology.icon" :alt="technology.name"/>
      </div>
    </div>

  </section>
</template>

<script>
export default {
  name: 'About',
  data() {
    let daysWorkExp = (new Date().getTime() - new Date(2017, 9, 1).getTime() +
        (new Date(2017, 9, 1).getTime() - new Date(2014, 9, 1).getTime()) * 2 / 3) / (1000 * 3600 * 24);

    return {
      socials: [{
        link: 'https://github.com/AntoineBouquet',
        icon: 'github'
      }, {
        link: 'https://www.linkedin.com/in/bouquet-antoine/',
        icon: 'linkedin'
      }, {
        custom: true,
        link: 'https://www.malt.fr/profile/bouquetantoine',
        icon: require('@/assets/about/malt.svg'),
        name: "malt"
      }],
      nbYearsExp: Math.floor(daysWorkExp / 365),
      nbDaysExp: Math.floor(daysWorkExp % 365),
      technologies: [{
        name: 'Flutter',
        icon: require('@/assets/about/flutter.svg'),
        custom: true
      }, {
        name: 'Angular',
        icon: 'angular'
      }, {
        name: 'Ionic',
        icon: require('@/assets/about/ionic.svg'),
        custom: true
      }, {
        name: 'Node.js',
        icon: 'node-js'
      }, {
        name: 'NestJS',
        icon: require('@/assets/about/nestjs.svg'),
        custom: true
      }, {
        name: 'Java Spring',
        icon: require('@/assets/about/java-spring.svg'),
        custom: true
      }, {
        name: 'Docker',
        icon: 'docker'
      }]
    };
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import 'src/scss/variables';

.container {
  background-color: #ececec;

  > img {
    width: 200px;
    height: 200px;
    border-radius: 200px;
  }

  .social-buttons {
    padding: 20px 0;
    display: flex;
    justify-content: center;
    text-decoration: none;

    .social-button {
      margin: 0 5px;

      cursor: pointer;

      $social-button-size: 40px;

      width: $social-button-size;
      height: $social-button-size;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: calc($social-button-size * 3 / 5);
      border-radius: $social-button-size;

      background-color: $default;

      &:hover {
        background-color: $default-hover;
      }

      * {
        color: white;
      }

      img {
        width: calc($social-button-size * 3 / 5);
      }
    }
  }

  h1 {
    margin: 10px 0 30px 0;
  }

  p {
    font-size: 16px;
    line-height: 30px;
    margin: 5px 0;

    a {
      outline: none;
      color: $primary-hover;
      text-decoration: none;

      &:hover {
        text-decoration: underline
      }
    }
  }

  span.heart {
    font-size: 23px;
  }

  span.info {
    border-bottom: 1px dashed #000;
    cursor: help;
    position: relative;
    padding-bottom: 3px;
  }

  .technologies {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;

    .technology {
      opacity: 0.5;
      margin: 4px 8px;
      -webkit-user-select: none;
      -webkit-touch-callout: none;

      &:hover {
        opacity: 1;
      }

      > * {
        width: auto;
        height: 40px;
      }
    }
  }

}

</style>
