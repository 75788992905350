import {createApp} from 'vue'
import App from './App.vue'
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faBars, faChevronCircleDown, faLink} from "@fortawesome/free-solid-svg-icons";
import {faAngular, faDocker, faGithub, faJava, faLinkedin, faNodeJs} from "@fortawesome/free-brands-svg-icons";
import {VTooltip} from "v-tooltip";
import 'v-tooltip/dist/v-tooltip.css'
import {createI18n} from 'vue-i18n'
import en from "@/assets/locale/en.json"
import fr from "@/assets/locale/fr.json"

library.add(faChevronCircleDown);
library.add(faBars);
library.add(faLink);
library.add(faGithub);
library.add(faLinkedin);
library.add(faAngular);
library.add(faNodeJs);
library.add(faDocker);
library.add(faJava);

const i18n = createI18n({
  locale: 'fr',
  messages: {
    en,
    fr
  },
  fallbackLocale: 'en'
})


createApp(App)
  .component("font-awesome-icon", FontAwesomeIcon)
  .directive('tooltip', VTooltip)
  .use(i18n)
  .mount('#app')
