<!-- Source code : https://loading.io/css/ -->
<template>
  <div class="flag ripple" v-if="$i18n.locale === 'en'" v-on:click="$i18n.locale = 'fr'"><span>🇫🇷</span></div>
  <div class="flag ripple" v-if="$i18n.locale === 'fr'" v-on:click="$i18n.locale = 'en'"><span>🇺🇸</span></div>
</template>

<script>
export default {
  name: "translate.vue"
}
</script>

<style lang="scss" scoped>
@import 'src/scss/_variables.scss';

.flag {
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;

  /* If you want to implement it in very old browser-versions */
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */

  /* The rule below is not implemented in browsers yet */
  -o-user-select: none;

  /* The rule below is implemented in most browsers by now */
  user-select: none;
}

/* Ripple effect */
.ripple {
  background-position: center;
  transition: background 0.8s;
}

.ripple:hover {
  background: $primary radial-gradient(circle, transparent 1%, $primary 1%) center/15000%;
}

.ripple:active {
  background-color: #d2a303;
  background-size: 100%;
  transition: background 0s;
}
</style>
