<template>
  <div class="project">
    <div class="image" :style="{ 'backgroundImage': 'url(\'' + project.image + '\')' }"></div>

    <div class="infos">
      <h4 class="name">{{ project.title }}</h4>
      <p class="description">{{ $t(project.description) }}</p>
      <div class="chips">
        <div class="chip tag" v-for="tag of project.tags" v-bind:key="tag"
             :style="{'background-color' : getLightColor() }">
          {{ tag }}
        </div>
      </div>
      <div class="chips">
        <div class="chip link" v-for="link of project.links" v-bind:key="link">
          <a :href="link.url" target="_blank" rel="nofollow">
            <font-awesome-icon icon="link" /> {{ $t(link.name) }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectItem',
  data() {
    return {};
  },
  props: ['project'],
  methods: {
    getLightColor() {
      return "hsl(" + Math.random() * 360 + ", 100%, 75%)";
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import 'src/scss/variables';

.project {
  width: 360px;
  height: 460px;

  @media screen and (max-width: $very-small-page-breakpoint) {
    width: 300px;
    height: 400px;
  }

  margin: 15px;
  display: flex;
  flex-direction: column;

  -webkit-box-shadow: 0 0 15px 5px rgba(0,0,0,0.25);
  box-shadow: 0 0 15px 5px rgba(0,0,0,0.25);
  background-color: #e2e2e2;
  overflow: hidden;
  border-radius: 5px;

  .image {
    flex: 1;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
  }

  .infos {
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 25px 35px 15px 35px;

    .name {
      font-family: "Roboto Slab", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 20px;
      font-weight: 500;
      margin: 5px 0;
    }

    .description {
      color: #777;
      font-size: 16px;
    }

    .chips {
      display: flex;

      .chip {
        padding: 5px 8px;
        border-radius: 11px;
        font-size: 12px;
        margin: 3px;
      }

      .chip.tag {
        color: black;

        &:hover {
          filter: brightness(90%);
        }
      }

      .chip.link {
        background-color: #353535;
        cursor: pointer;

        &:hover {
          filter: brightness(120%);
        }

        a {
          text-decoration: none;
          color: white;
        }
      }
    }
  }
}
</style>
